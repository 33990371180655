html,
body {
	height: 100%;
	width: 100%;
	box-sizing: border-box;
	display: flex;
	font-family: "Heebo", sans-serif;
	font-size: 1rem;
}

.App {
	user-select: none;
	position: relative;
	display: flex;
	box-sizing: border-box;
	text-align: center;
	justify-content: center;
	align-items: center;
	height: 90vmin;
	width: 90vmin;
	min-width: 400px;
	min-height: 400px;
	overflow-y: auto;
	background-color: whitesmoke;
	box-shadow: 0 20px 80px 5px #423b3bb4;
	transition: 0.4s;
}

.center-outer-div {
	align-items: center;
	justify-content: center;
	display: flex;
	height: 100vh;
	width: 100vw;
}

.corner-selected {
	height: 90vh;
	transition: 0.4s;
}

.corner1 {
	overflow: hidden;
	position: absolute;
	top: 0;
	left: 0;
	height: 50%;
	width: 50%;
	background: slategray;
}

.corner2 {
	margin: 0 auto;
	overflow: hidden;
	position: absolute;
	top: 0;
	right: 0;
	height: 50%;
	width: 50%;
	background: salmon;
}

.corner3 {
	overflow: hidden;
	position: absolute;
	bottom: 0;
	left: 0;
	height: 50%;
	width: 50%;
	background: rgb(73, 182, 173);
}

.corner4 {
	overflow: hidden;
	position: absolute;
	bottom: 0;
	right: 0;
	height: 50%;
	width: 50%;
	background-color: rgb(33, 155, 196);
}

.grow {
	position: absolute;
	padding: 0;
	height: 60%;
	width: 60%;
	z-index: 9999;
	box-shadow: 0 0 40px 5px #423b3bb4;
	transition: width 500ms, height 500ms;
}

.shrink {
	/* border: 1px solid black; */
	height: 50%;
	width: 50%;
	z-index: 0;
	transition: width 500ms, height 500ms, z-index 500ms, background-color 1000ms;
}

.selected {
	height: 100%;
	width: 100%;
	z-index: 9999;
	background-color: whitesmoke;
	overflow-y: auto;
	transition: width 500ms, height 500ms, background-color 1500ms;
	animation: 1.5s delay-overflow;
}

@keyframes delay-overflow {
	0% {
		overflow-y: hidden;
	}
	100% {
		overflow-y: auto;
	}
}

button {
	cursor: pointer;
	background: white;
	border: 1px solid black;
	transition: 0.5s;
	border-radius: 4px;
}

button:hover {
	box-shadow: 4px 4px 16px 0 rgba(0, 0, 0, 0.24),
		0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.skills-container {
	width: 100%;
	display: flex;
}

.skills-div {
	display: flex;
	flex-direction: column;
	width: 33%;
}

.skills-div ul {
	padding: 5px;
}

.subject-div {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.corner-full-container {
	margin: 0 auto;
	margin-top: 5vmin;
	width: 80vmin;
	min-width: 400px;
	height: 80vmin;
}

.experience-container {
	text-align: left;
}

.experience-container h1 {
	text-align: center;
}

.experience-container h3 {
	margin-bottom: 0;
}

.experience-container h4 {
	margin-top: 0;
	margin-bottom: 0.4rem;
}

.education-container {
	text-align: left;
}

.education-container h1 {
	text-align: center;
}

.education-container h3 {
	margin-bottom: 0;
}

.education-container h4 {
	margin-top: 0;
	margin-bottom: 0;
}

.projects-container {
	text-align: left;
}

.projects-container h1 {
	text-align: center;
}

.projects-container h3 {
	margin-bottom: 0;
}

.projects-container h4 {
	margin-top: 0;
	margin-bottom: 0;
}

.project-div {
	display: flex;
	align-items: center;
}

.project-text a {
	color: black;
}

.project-image {
	margin: 1rem;
	max-width: 40%;
	object-fit: contain;
}

.image-left {
	flex-direction: row-reverse;
}

@media (max-width: 640px) {
	.project-div {
		flex-direction: column;
	}
	.image-left {
		flex-direction: column;
	}
	.project-image {
		max-width: 100%;
	}
}
